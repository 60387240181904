@.onYouTubeIframeAPIReady = ->
    player = undefined
    volumeVal = undefined
    myPlayerState = false
    $(document).ready(->
        $("#volume-input").on("change input", ->
            val = parseInt($(this).val())
            volumeVal = val
            player.setVolume(val)
            if val == 0
                $(this).siblings(".sound").addClass("disabled")
            else
                $(".sound").removeClass("disabled")
        )
        $(".main-banner__video-control .sound").addClass("disabled")
        $(".main-banner__video-control .replay").on("click", ->
            player.seekTo(0);
            $(".main-banner__video-control .play").addClass('disabled')
        )
        $(".main-banner__video-control .play").on("click", ->
            $(this).toggleClass("disabled")
            if $(this).hasClass("disabled")
                player.playVideo()
            else
                player.stopVideo()
        )
        $(".main-banner__video-control .sound").on("click", ->
            $(this).toggleClass("disabled")
            if $(this).hasClass("disabled")
                player.setVolume(0)
                $("#volume-input").val(0)
            else
                if volumeVal == undefined
                    volumeVal = 50
                player.setVolume(volumeVal)
                $("#volume-input").val(volumeVal)
        )
        $(".player-start").on("click", ->
            player.playVideo()
        )
    )

    onPlayerReady = (event) ->
        player.playVideo()
        player.setVolume(0)
        timer = setInterval ->
            checkIsPlaying()
        , 1000

        checkIsPlaying = ->
            if myPlayerState
                clearInterval timer
                $(".main-banner__video-control .play").addClass('disabled')
                return
            player.playVideo()
            return


    onPlayerStateChange = (event) ->
        if (event.data == YT.PlayerState.PLAYING)
            myPlayerState = true
            $(".player-start").remove()
        containerButton = $("#video-container")

    videoId = $("#player").data("video-id")
    showControls = $("#player").data("show-controls") or 0

    player = new (YT.Player)("player",
        videoId: videoId
        playerVars:
            "autoplay": 1
            "controls": showControls
            "loop": 1
            "showinfo": 0
            "rel": 0
            "modestbranding": 1
            "iv_load_policy": 3
        events:
            "onReady": onPlayerReady
            "onStateChange": onPlayerStateChange
    )

    return

@.youTubeIframeHandler = ->
    tag = document.createElement("script")
    tag.src = "https://www.youtube.com/iframe_api"
    firstScriptTag = document.getElementsByTagName("script")[0]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
    player = undefined
    return
